import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Commons } from '../shared/Commons';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private userLoggedIn = new Subject<boolean>();
  private data = new BehaviorSubject<any>(null);
  private KEY_OPT = 'C_opt'
  private KEY_TRX_BASE = 'trxBase'
  private KEY_REGISTER = 'register'
  private KEY_LANG = 'lang'

  constructor() {
    this.userLoggedIn.next(false);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
    if (!userLoggedIn) {
      sessionStorage.clear()
    }
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  setData(newData: any) {
    this.data.next(newData);
  }

  setSessionData(key: string, data: any) {
    sessionStorage.setItem(key, Commons.encryptDataLocal(data))
  }

  clearSessionData(key: string) {
    sessionStorage.removeItem(key)
  }

  getSessionData(key: string): any | null {
    const resultString: string | null = sessionStorage.getItem(key)
    if (resultString != null) {
      return Commons.decryptDataLocal(resultString)
    }
    return null
  }

  setLang(option: string) {
    sessionStorage.setItem(this.KEY_LANG, option)
  }

  getLang() {
    return sessionStorage.getItem(this.KEY_LANG)
  }

  setConfigurationOption(option: string) {
    sessionStorage.setItem(this.KEY_OPT, option)
  }

  getConfigurationOption() {
    return sessionStorage.getItem(this.KEY_OPT)
  }

  setTransactionBase(trxBase: any) {
    this.setSessionData(this.KEY_TRX_BASE, trxBase)
  }

  getTransactionBase() {
    return this.getSessionData(this.KEY_TRX_BASE)
  }

  setRegisterData(personalDataSession: any) {
    this.setSessionData(this.KEY_REGISTER, personalDataSession)
  }

  getRegisterData() {
    return this.getSessionData(this.KEY_REGISTER)
  }

  getData() {
    return this.data.asObservable();
  }
}
