<div class="card-header border-0">
  <form [formGroup]="fForm">
    <div class="row">
      <div class="{{(isMobile) ? 'col-12 text-center py-2':'col-6'}}">
        <h5 *ngIf="paginator.addingFilters" class="mb-0">{{ "label.filters" | transloco }}</h5>
        <h5 *ngIf="!paginator.addingFilters" class="mb-0">{{ "label.without-filters" | transloco }}</h5>
      </div>
      <div class="card py-2  {{(isMobile) ? 'col-12':'col-6'}} text-right">
        <div class="row">
          <div class="{{(isMobile) ? 'col-12':'col-6'}}">
            <select *ngIf="paginator.addingFilters" class="form-control" (change)="onChange($event.target)"
              formControlName="fField">
              <option value="" selected disabled>
                {{ "label.select-filter" | transloco }}...
              </option>
              <option *ngFor="let item of filtersInSelect" value="{{ item.field }}">
                {{ item.label | transloco }}
              </option>
            </select>
          </div>
          <div *ngIf="!filterDateSelected" class="{{(isMobile) ? 'col-12':'col-6'}}">
            <input *ngIf="paginator.addingFilters" type="text" formControlName="fValue" class="form-control"
              placeholder="{{'label.filter'|transloco}}" />
          </div>
          <mat-form-field *ngIf="filterDateSelected">
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)">
            </mat-datepicker>
          </mat-form-field>
          <div class="col-12 text-center">
            <button *ngIf="!paginator.addingFilters" class="btn btn-dark btn-small" style="font-size: 12px"
              (click)="addFilters()">
              {{ "label.add-filter" | transloco }}
            </button>
          </div>
          <div class="col-12 text-right">
            <button *ngIf="paginator.addingFilters" class="btn btn-dark btn-small" (click)="applyFilter()"
              [disabled]="fForm.invalid" style="font-size: 12px">
              <!--i class="tf-ion-ios-checkmark active-green"></i-->
              {{ "label.apply" | transloco }}
            </button>
            <button *ngIf="paginator.addingFilters" class="btn btn-danger btn-small" (click)="clearFilters()"
              style="font-size: 12px">
              {{ "label.clean-all" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div *ngFor="let item of filtersInSelect">
        <div *ngIf="item.enabled" class="col-12">
          <div class="alert alert-info alert-dismissible fade show" role="alert">
            <span class="alert-inner--text"><strong><small>{{ item.label | transloco }}</small></strong>
              {{ item.value }}</span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="removeFilter(item.field)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>