import { ChangeDetectorRef, Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from './services/session.service';
import { Commons } from './shared/Commons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // some fields to store our state so we can display it in the UI
  idleState = "NOT_STARTED";
  countdown: number|null = null;
  lastPing: Date|null = null;

  // add parameters for Idle and Keepalive (if using) so Angular will inject them from the module
  constructor(
    private idle: Idle,
    keepalive: Keepalive,
    cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private sessionService: SessionService,
    private titleService: Title,
    private metaService: Meta
  ) {
    // set idle parameters
    idle.setIdle(environment.sessionTimeMins * 60); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(5); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    idle.onIdleStart.subscribe(() => {
      this.closeSession()
      this.idleState = "IDLE";
    });
    // do something when the user is no longer idle
    idle.onIdleEnd.subscribe(() => {
      Commons.sessionRenew()
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      cd.detectChanges(); // how do i avoid this kludge?
    });
    // do something when the user has timed out
    idle.onTimeout.subscribe(() => {
      this.closeSession()
      this.idleState = "TIMED_OUT"
    });
    // do something as the timeout countdown does its thing
    idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // set keepalive parameters, omit if not using keepalive
    keepalive.interval(15); // will ping at this interval while not idle, in seconds
    keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings

    this.sessionService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.reset()
      } else {
        idle.stop();
      }
    })
  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    this.loadMetaTags()
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.idleState = "TIMED_OUT"
      this.sessionService.setUserLoggedIn(false);
      this.router.navigate([Commons.PATH_LOGIN], { queryParams: { arg: 'closed' } })
    }
  }

  loadMetaTags() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {
 
        let rt = this.getChild(this.route)
 
        rt.data.subscribe((data: any) => {
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 

          if (data.content) {
            this.metaService.updateTag({ content: data.content, name: 'keywords' })
          } else {
            this.metaService.removeTag("content")
          }
 
        })
 
      })
 
  }

  getChild(activatedRoute: ActivatedRoute) :any{
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
}
