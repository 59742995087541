import { Injectable } from '@angular/core';
import { TranslocoService, getBrowserLang } from '@ngneat/transloco';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageUtilService {

  lang?: string

  languages = ['es','en','de','it','pt']

  constructor(
    private translocoService: TranslocoService,
    private sessionService: SessionService
  ) {
    this.lang = getBrowserLang();
    const sessionLang = sessionService.getLang()
    if(sessionLang){
      this.lang = sessionLang
    }
    if (this.lang == undefined) {
      this.lang = this.translocoService.getActiveLang()
    }
    this.translocoService.setActiveLang(this.lang)
  }

  translate(arg: string) {
    return this.translocoService.translate(arg)
  }

  getLangActive() {
    return this.translocoService.getActiveLang() 
  }

  getValidLanguages() {
    return this.languages 
  }

  setLanguage(arg:string){
    const vLang = this.languages.find(lang => lang === arg.toLocaleLowerCase())
    if(vLang){
      this.sessionService.setLang(vLang)
      this.translocoService.setDefaultLang(vLang)
      this.translocoService.setActiveLang(vLang)
    }
  }
}
