import { Component } from '@angular/core';

@Component({
  selector: 'app-superadmin-layout',
  templateUrl: './superadmin-layout.component.html',
  styleUrls: ['./superadmin-layout.component.css']
})
export class SuperadminLayoutComponent {

  constructor() { }

  ngOnInit() {
  }

}
