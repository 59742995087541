import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Commons } from 'src/app/shared/Commons';
import { AlertModalComponent } from 'src/app/shared/modals/alert-modal/alert-modal.component';
import { SelectLanguageModalComponent } from 'src/app/shared/modals/select-language-modal/select-language-modal.component';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { LanguageUtilService } from 'src/app/services/language-util.service';
import { CustomersService } from 'src/app/services/customers.service';

@Component({
  selector: 'app-superadmin-header',
  standalone: false,
  templateUrl: './superadmin-header.component.html',
  styleUrl: './superadmin-header.component.css'
})
export class SuperadminHeaderComponent implements OnInit {

  appName = environment.appName

  PATH_MAIN = Commons.PATH_MAIN_SUPERUSER_LOGGED
  PATH_HOME = Commons.PATH_MAIN
  PATH_LOGIN = Commons.PATH_LOGIN
  PATH_ADMIN_CLIENTS = Commons.PATH_MAIN_SUPERUSER_CLIENTS

  getScreenWidth: any;
  mobileWidth: number = Commons.MOBILE_WIDTH
  sessionIsOpen: boolean = Commons.sessionIsOpen()
  sessionObject: any = Commons.sessionObject()
  alertModal: MdbModalRef<AlertModalComponent> | null = null;
  title = 'label.access-link'
  selLangModal: MdbModalRef<SelectLanguageModalComponent> | null = null;
  @ViewChild('mainNavbar', { static: true }) mainNavbar!: ElementRef;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private modalService: MdbModalService,
    private langService: LanguageUtilService,
    private customersService: CustomersService
  ) { }

  ngOnInit(): void {
    this.sessionIsOpen = Commons.sessionIsOpen()
    this.sessionObject = Commons.sessionObject()
    this.getScreenWidth = window.innerWidth
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth
  }

  openLanguageModal() {
    this.selLangModal = this.modalService.open(SelectLanguageModalComponent)
    this.selLangModal.onClose.subscribe((lang: any) => {
      if (lang != this.langService.getLangActive()) {
        this.langService.setLanguage(lang)
        window.location.reload();
      }
    });
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      this.router.navigate([Commons.PATH_MAIN]).then(() => {
        window.location.reload();
      });
    }
  }

  getCustomerName() {
    let name = 'NO-NAME'
    if (this.sessionObject.customer) {
      name = this.sessionObject.customer.email
      if (this.sessionObject.customer.personal_data) {
        name = this.sessionObject.customer.personal_data.first_name
        if (this.sessionObject.customer.personal_data.last_name) {
          name = name + ' ' + this.sessionObject.customer.personal_data.last_name
        }
      }
    }
    return name
  }

  toSupport() {
    this.openNewWindow(Commons.PATH_SUPPORT)
  }

  openNewWindow(path: string) {
    this.customersService.createTransientAuth().subscribe({
      next: (v) => {
        Commons.openWithExternalToken(path, v.transient_auth)
      },
      error: (e) => {
        this.openModal('label.unknown-error', 'label.unknown-error-contact-retry', Commons.ICON_ERROR)
      },
      complete: () => { }
    })

  }

  getPlan() {
    const planCategory = Commons.getPlan()
    switch (planCategory) {
      case Commons.LICENCE_TRIAL:
        return 'label.trial-licence'
      case Commons.LICENCE_BASIC:
        return 'label.basic-licence'
      case Commons.LICENCE_MEDIUM:
        return 'label.medium-licence'
      case Commons.LICENCE_PREMIUM:
        return 'label.premium-licence'
      default:
        return 'label.without-licence'
    }
  }

  openModal(title: string, message: string, icon: string) {
    this.alertModal = this.modalService.open(AlertModalComponent, {
      data: { title: title, message: message, icon: icon },
    })
  }

  closeNavbar(): void {
    const navbar = this.mainNavbar.nativeElement;
    if (navbar.classList.contains('show')) {
      navbar.classList.remove('show');
    }
  }

}
