<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left mr-auto">
        <div class="footer-widget">
          <h4 class="mb-4">{{appName}} <small>v{{version}}</small></h4>
          <p class="lead">{{footerInfo}}</p>
        </div>
      </div>

      <div class="col-md-6 col-lg-2 col-sm-6 mb-5 mb-lg-0 text-center text-sm-left">
        <div class="footer-widget">
          <h4 class="mb-4">{{'label.more-info'|transloco}}</h4>
          <ul class="pl-0 list-unstyled mb-0">
            <li><a (click)="toAboutUs()">{{'label.about-us'|transloco}}</a></li>
            <li><a (click)="toStore()">{{'label.products'|transloco}}</a></li>
            <li><a (click)="toSupport()">{{'label.support'|transloco}}</a></li>
          </ul>
        </div>
      </div>

      <div class="col-md-6 col-lg-3 col-sm-6 text-center text-sm-left">
        <div class="footer-widget">
          <h4 class="mb-4"><a (click)="toSupport()">{{'label.contact'|transloco}}</a></h4>
          <ul class="pl-0 list-unstyled mb-5">
            <li class="d-lg-flex justify-content-between">{{'label.location'|transloco}} <span>
                {{officeLocation}}</span></li>
            <li class="d-lg-flex justify-content-between">{{'label.email'|transloco}} <span>{{contactEmail}}</span></li>
          </ul>

          <h5>{{'label.call-now'|transloco}} : {{contactPhone}}</h5>
        </div>
      </div>
    </div>
  </div>
</footer>


<div class="footer-btm py-4 ">
  <div class="container">
    <div class="row ">
      <div class="col-lg-6">
        <p class="copyright mb-0 ">&copy; {{ anio | date: "yyyy" }}{{' '}}<a href="{{providerWeb}}"
            target="_blank">{{orgName}}</a>. Todos los derechos reservados</p>
      </div>
      <div class="col-lg-6">
        <ul class="list-inline mb-0 footer-btm-links text-lg-right mt-2 mt-lg-0">
          <li class="list-inline-item"><a (click)="toPP()">{{'label.privacy-policy'|transloco}}</a></li>
          <li class="list-inline-item"><a (click)="toTC()">{{'label.terms-conditions'|transloco}}</a></li>
          <li class="list-inline-item"><a (click)="toCP()">{{'label.cookie-policy'|transloco}}</a></li>
          <li class="list-inline-item"><a (click)="toST()">{{'label.terms-sales'|transloco}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>