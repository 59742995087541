<nav class="navbar navbar-expand-lg navbar-light bg-white w-100 navigation" id="navbar">
  <div class="container">
    <a class="navbar-brand font-weight-bold" routerLink="/">{{ ownerDetail.company_name }}</a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar"
      aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse " id="main-navbar" #mainNavbar>
      <ul class="navbar-nav mx-auto">
        <li class="nav-item active" (click)="closeNavbar()">
          <a class="nav-link" routerLink="/">{{'label.home'|transloco}}</a>
        </li>

        <li *ngIf="sessionIsOpen" class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_ACCOUNTS}}">{{'label.accounts'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_PAY}}">{{'label.pay'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" (click)="toAbout()" href="">{{'label.about-us'|transloco}}</a>
        </li>

        <li class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" routerLink="{{PATH_CONTACT}}">{{'label.contact'|transloco}}</a>
        </li>

        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item" (click)="closeNavbar()">
          <a class="nav-link" (click)="openLanguageModal()">{{'language.title'|transloco}}</a>
        </li>
        <!-- Pages -->
        <li *ngIf="getScreenWidth < mobileWidth" class="nav-item dropdown dropdown-slide">
          <a *ngIf="sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{getCustomerName()}}
          </a>
          <a *ngIf="!sessionIsOpen" class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button"
            data-delay="350" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ 'label.login-invite' | transloco }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown4">
            <li *ngIf="sessionIsOpen && !isSuperUser"><a routerLink="{{PATH_MY_CUSTOMER}}" (click)="closeNavbar()">{{'label.profile'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen && isSuperUser"><a routerLink="{{PATH_SUPERADMIN_HOME}}" class="btn btn-small btn-transparent" (click)="closeNavbar()">{{'label.dashboard'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen && !isSuperUser"><a routerLink="{{PATH_MAIN}}" class="btn btn-small btn-transparent" (click)="closeNavbar()">{{'label.dashboard'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'login.title'|transloco}}</a></li>
            <li *ngIf="!sessionIsOpen"><a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block" (click)="closeNavbar()">{{'register.title'|transloco}}</a></li>
            <li *ngIf="sessionIsOpen"><a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a></li>
          </ul>
        </li><!-- /Pages -->

      </ul>
    </div>
    <!-- Navbar-collapse -->

    <ul class="top-menu list-inline mb-0 d-none d-lg-block" id="top-menu">
      <li class="list-inline-item"><a href="#"></a></li>
      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" (click)="openLanguageModal()" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-world mr-3"></i>
        </a>
      </li>

      <li class="dropdown cart-nav dropdown-slide list-inline-item">
        <a href="#" class="dropdown-toggle cart-icon" data-toggle="dropdown" data-hover="dropdown">
          <i class="tf-ion-ios-person mr-3"></i>
        </a>
        <div class="dropdown-menu cart-dropdown">
          <li *ngIf="sessionIsOpen">
            <h3>{{getCustomerName()}}</h3>
          </li>
          <li *ngIf="sessionIsOpen && !isSuperUser"><a routerLink="{{PATH_MY_CUSTOMER}}">{{'label.profile'|transloco}}</a></li>
          <li *ngIf="sessionIsOpen && !isSuperUser"><a routerLink="{{PATH_MAIN}}" class="btn btn-small btn-transparent">{{'label.dashboard'|transloco}}</a></li>
          <li *ngIf="sessionIsOpen && isSuperUser"><a routerLink="{{PATH_SUPERADMIN_HOME}}" class="btn btn-small btn-transparent">{{'label.dashboard'|transloco}}</a></li>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_LOGIN}}" class="btn btn-small btn-main btn-block">{{'login.title'|transloco}}</a>
          </div>
          <div *ngIf="!sessionIsOpen" class="text-center cart-buttons mt-3">
            <a routerLink="{{PATH_REGISTER}}" class="btn btn-small btn-main btn-block">{{'register.title'|transloco}}</a>
          </div>
          <div *ngIf="sessionIsOpen" class="text-center cart-buttons mt-3">
            <a (click)="closeSession()" class="btn btn-small btn-main btn-block">{{'label.close'|transloco}}</a>
          </div>
        </div>
      </li>
  </ul>
  </div>
</nav>